<script setup>
document.title = "프리랜싱"

import { inject } from "vue"
import Page from "../components/Page.vue"
// import Stack from '../components/Stack.vue';
// import Freelancing_Tasks from '../components/Freelancing_Tasks.vue';

const { lang } = inject("lang")

// const taskDefinition_ALLCL = [
//     { title: '프론트엔드', tasks: [
//         { title: 'React Native 프로젝트 셋업', mh: 0 },
//         { title: '화면 제작', tasks: [
//             { title: '로딩', mh: 2, note: '로고 위탁 개발 시 추가 비용 발생' },
//             { title: '회원가입 및 로그인', tasks: [
//                 { title: '회원가입', tasks: [
//                     { title: '이메일 입력', mh: 0 },
//                     { title: '이메일 확인', mh: 0 },
//                     { title: '정보 입력', mh: 0 },
//                 ]},
//                 { title: '로그인', mh: 5 },
//             ]},
//             { title: '문제 찾기 tab', tasks: [
//                 { title: '문제 찾기', mh: 0 },
//                 { title: '문제 상세 정보', mh: 0 },
//                 { title: '문제 사진 보기', mh: 0 },
//                 { title: '신규 문제 보기', mh: 0 },
//                 { title: '클라이밍장 찾기', mh: 0 },
//             ]},
//             { title: '추천 문제 tab', mh: 0 },
//             { title: '프로필 tab', tasks: [
//                 { title: '비로그인 시 로그인', mh: 0 },
//                 { title: '프로필', mh: 0 },
//                 { title: '프로필 햄버거 메뉴', mh: 0 },
//                 { title: '일일기록', mh: 0 },
//                 { title: '내보내기', mh: 0 },
//                 { title: '개인정보 수정', mh: 0 },
//             ]},
//                 { title: '네트워킹', tasks: [
//                 { title: '팔로잉/팔로워 목록', mh: 0 },
//                 { title: '타인 프로필 (프로필 변형)', mh: 0 },
//                 { title: '타인 일일기록 (일일기록 변형)', mh: 0 },
//                 { title: '검색 tab', mh: 0 },
//                 { title: '피드 tab', mh: 0 },
//             ]}
//         ]}
//     ]},
//     { title: '백엔드', tasks: [
//         { title: 'Express 및 MongoDB 프로젝트 셋업', mh: 0 },
//         { title: '', tasks: [
//         { title: 'user', tasks: [
//             { title: 'schema', mh: 0 },
//             { title: 'router', mh: 0 },
//             { title: 'controller', mh: 0 }
//             ]},
//             { title: 'brand', tasks: [
//             { title: 'schema', mh: 0 },
//             { title: 'router', mh: 0 },
//             { title: 'controller', mh: 0 }
//             ]},
//             { title: 'level', tasks: [
//             { title: 'schema', mh: 0 },
//             { title: 'router', mh: 0 },
//             { title: 'controller', mh: 0 }
//             ]},
//             { title: 'branch', tasks: [
//             { title: 'schema', mh: 0 },
//             { title: 'router', mh: 0 },
//             { title: 'controller', mh: 0 }
//             ]},
//             { title: 'wall', tasks: [
//             { title: 'schema', mh: 0 },
//             { title: 'router', mh: 0 },
//             { title: 'controller', mh: 0 }
//             ]},
//             { title: 'problem', tasks: [
//             { title: 'schema', mh: 0 },
//             { title: 'router', mh: 0 },
//             { title: 'controller', mh: 0 }
//             ]},
//             { title: 'log', tasks: [
//             { title: 'schema', mh: 0 },
//             { title: 'router', mh: 0 },
//             { title: 'controller', mh: 0 }
//             ]},
//             { title: delete-'requests', tasks: [
//             { title: 'schema', mh: 0 },
//             { title: 'router', mh: 0 },
//             { title: 'controller', mh: 0 }
//             ]},
//             { title: 'auth', tasks: [
//             { title: 'schema', mh: 0 },
//             { title: 'router', mh: 0 },
//             { title: 'controller', mh: 0 }
//             ]},
//             { title: 'counter', tasks: [
//             { title: 'schema', mh: 0 },
//             { title: 'router', mh: 0 },
//             { title: 'controller', mh: 0 }
//             ]},
//         ]}
//     ]}
// ]

// const projects = [
//     {
//         '프론트엔드': { mh: 0 },
//             '로딩': {  mh: 2, note: '로고 위탁 개발 시 추가 비용 발생' }
//             },
//             '회원가입 및 로그인': {
//                 '회원가입': {  mh: 0 }, mh: 0 }, mh: 0 },
//                  }, mh: 5 },
//             },
//             '문제 찾기 tab': { mh: 0 }, mh: 0 }, mh: 0 }, mh: 0 }, mh: 0 },
//             }, mh: 0 },
//             '프로필 tab': { mh: 0 }, mh: 0 }, mh: 0 }, mh: 0 }, mh: 0 }, mh: 0 },
//             },
//             '네트워킹': { mh: 0 }, mh: 0 }, mh: 0 }, mh: 0 }, mh: 0 }
//             }
//         },
//         '백엔드': { mh: 0 },

//         }
//     }
// ];
</script>

<template>
  <Page
    :supertitle="
      {
        ko: '이건희 —',
        en: 'Gunhee Yi —',
      }[lang]
    "
    :title="
      {
        ko: '프리랜싱 업무 소개',
        en: 'Freelancing Works',
      }[lang]
    "
    koreanOnly
  >
    <h2>작업자 소개</h2>
    <!-- <figure class="image" style="text-align: left">
      <img src="https://images.gunh.ee/freelancing/profile.jpg" style="width: 300px" />
    </figure> -->
    <p>
      <b>이건희</b> (<a href="tel:+82-10-9950-1390" target="_blank">+82-10-9950-1390</a>
      /
      <a href="mailto:gunny2676@gmail.com" target="_blank">gunny2676@gmail.com</a>)
    </p>
    <p>
      자세한 항목당 비용 견적과 예상 기한을 무료로 산정하여 제공해드리니 위 연락처로 자유롭게
      문의해주세요.
    </p>
    <ul>
      <li>KAIST 전산학 및 항공우주공학 복수전공 졸업</li>
      <li>웹을 전문으로 한 프로그래밍 경력 8년차</li>
      <li>
        스마트조명 자동 제어 시스템 / 사용자 참여형 클라이밍 문제 데이터베이스 / 온디바이스 시선추적
        모델 및 파생 서비스 등을 개발<br />
        (아래 '프로젝트 예시' 문단 및 별도
        <a href="/portfolio" target="_blank">포트폴리오</a>를 참조 바랍니다.)
      </li>
    </ul>

    <h2>작업 분야</h2>

    <p>
      기본적으로 아래 항목들의 풀스택 개발을 전문적으로 수행합니다. 프론트/백엔드 등 일부 분야의
      개발도 물론 가능합니다.
    </p>
    <p>
      아래 목록에 속하지 않더라도 코드로 구현 가능한 프로젝트라면 대부분 수행할 수 있습니다.<br />
      (예시: 스마트 조명 자동 제어 시스템, 페이스북 저장된 게시물 / 유튜브 나중에 볼 동영상 일괄
      삭제 도구 등)
    </p>

    <h3>웹 애플리케이션</h3>
    <p>
      크롬, 사파리 등의 브라우저에서 동작하는 웹 서비스를 뜻합니다. 모바일 이외에 데스크톱
      환경에서도 동작해야 하거나 빠른 배포가 필요한 프로젝트에 적합합니다.
    </p>
    <p>예시) 개인 블로그 / 포트폴리오 사이트, 출결 / 물품 대여 관리 시스템</p>

    <h3>크로스플랫폼 모바일 애플리케이션</h3>
    <p>iOS 및 Android에서 모두 설치되어 동작할 수 있는 모바일 앱을 뜻합니다.</p>

    <h3>브라우저 확장 프로그램</h3>
    <p>
      크롬, 파이어폭스 등의 브라우저에 설치해 사용할 수 있는 확장 프로그램을 뜻합니다. 이미 존재하는
      웹서비스의 기능을 보강/차단하거나, 업무를 자동화하는데 용이합니다.
    </p>
    <p>예시) 유튜브 쇼츠 차단 / 강의 동영상 플레이어 단축키 지원 보강</p>

    <h3>웹 크롤링 도구</h3>
    <p>
      웹을 통해 접근 가능한 정보를 수집할 수 있는 도구를 뜻합니다. 손수 작업하기에 방대한 양의
      정형/비정형 데이터를 다루는 데에 적합합니다. 수집된 데이터는 엑셀이나 CSV 파일 등 원하시는
      방식으로 제공해드리며, 데스크톱이나 라즈베리파이, 클라우드 서버 등 원하시는 기기에서
      지속적으로 수집하도록 설정해드릴 수도 있습니다.
    </p>
    <p>예시) 특정 키워드를 포함하는 뉴스 기사 수집 / 실시간 고속버스 잔여 좌석 조회</p>

    <h2>개발 비용</h2>
    <p>
      개발 비용은 먼저 개발에 소요되는 맨아워(man-hour)를 추산한 뒤 여기에 맨아워 당 단가를 곱하는
      방식으로 산정합니다. 이렇게 계산한 비용은 외주 중개 플랫폼을 통한 개발보다 최대 50% 이상
      저렴합니다. 공익적 용도의 서비스의 경우 단가 인하를 별도 협의할 수 있습니다.
    </p>

    <figure class="image">
      <figcaption style="margin-top: 0em; margin-bottom: 0.5em">
        웹페이지 개발 비용 산정 예시
      </figcaption>
      <table style="margin-left: auto; margin-right: auto">
        <th>구분</th>
        <th>특징</th>
        <th>예시</th>
        <th>예상 비용 (단위: 만원)</th>
        <tr>
          <td>간단한 앱</td>
          <td>
            <ul>
              <li>제한된 페이지</li>
              <li>기본 설계</li>
              <li>최소 기능</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>개인 블로그</li>
              <li>포트폴리오 사이트</li>
              <li>이벤트 등 소규모 사이트 및 랜딩 페이지</li>
            </ul>
          </td>
          <td>100 ~ 600</td>
        </tr>
        <tr>
          <td>소규모 사업용 웹사이트</td>
          <td>
            <ul>
              <li>많은 페이지</li>
              <li>기본적인 SEO</li>
              <li>조금 복잡한 기능 (e.g. 소셜미디어 연동, 연락처 등)</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>병원 사이트</li>
              <li>헬스장 사이트</li>
              <li>지역 식당 사이트</li>
            </ul>
          </td>
          <td>600 ~ 1,700</td>
        </tr>
        <tr>
          <td>이커머스</td>
          <td>
            <ul>
              <li>구매 및 결제 기능</li>
              <li>상품 결제 기능</li>
              <li>관리자 및 사용자 계정</li>
              <li>주문 관리를 위한 백엔드 시스템 통합</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>패션, 전자제품, 서점 등 이커머스</li>
              <li>중개 판매 사이트(e.g. 이베이)</li>
            </ul>
          </td>
          <td>1,700 ~ 6,000</td>
        </tr>
        <tr>
          <td>커스텀 웹사이트</td>
          <td>
            <ul>
              <li>고급 기능</li>
              <li>특정 비즈니스 요구에 맞춤</li>
              <li>정교한 설계</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>SNS 플랫폼</li>
              <li>교육 및 강의 플랫폼</li>
              <li>직업 탐색 플랫폼 (e.g. 잡코리아, 사람인)</li>
              <li>콘텐츠 공유 및 협업 플랫폼 (e.g. 구글 드라이브)</li>
              <li>커스텀 CRM 시스템</li>
            </ul>
          </td>
          <td>6,000 ~ 13,000</td>
        </tr>
      </table>
    </figure>

    <figure class="image">
      <figcaption style="margin-top: 0em; margin-bottom: 0.5em">
        참고: 외주 중개 플랫폼에서 앱 개발 시 (출처: 크몽 블로그)
      </figcaption>
      <img src="https://images.gunh.ee/freelancing/kmong.jpg" style="width: 500px" />
    </figure>

    <h2>개발 기간</h2>
    <p>
      다수 프로젝트에 재사용할 수 있는 템플릿과 파이프라인을 구축해두어 빠르게 개발할 수 있습니다.
      출결 / 물품 대여 관리 등 간단한 기능을 가진 서비스는 빠르면 1주 안에도 완성이 가능합니다. 주
      당 투입 가능한 시간은 위탁 시기에 따라 다르고, 개인 작업으로 기한을 맞추기 어렵다면 내부적으로
      팀을 소집하여 작업하는 것이 가능합니다.
    </p>

    <h2>기술 스택</h2>
    <ul>
      <li>frontend: React(Next.js) or Vue.js</li>
      <li>backend: Express (Node.js)</li>
      <li>database: MongoDB Atlas (document DB)</li>
      <li>server infrastructure: AWS EC2</li>
    </ul>
    <p>
      기본적으로 위 조합을 자주 사용하여 서비스를 개발합니다. 그러나 사용을 원하시는 스택이 따로
      있다면 최대한 맞추어드립니다. 아래 나열한 기술들은 모두 사용 경험이 있습니다:
    </p>
    <!-- <p>
        React(Next.js) / React Native / Vue.js / Express / Flask / Node.js / JavaScript / TypeScript / Python / C / C++ / Objective-C / Swift / MATLAB / MongoDB / MySQL / Docker / AWS EC2 / ECR / S3 / DynamoDB / Google Cloud Platform / Google Analytics / Google Tag Manager / Ubuntu(Linux) / Windows / Mac / Raspberry Pi / Arduino
    </p> -->

    <ul>
      <li>
        언어 — JavaScript (Node.js) · TypeScript · Python · C · C++ · Objective-C · Swift · MATLAB ·
        HTML5 · CSS5 · SCSS · Markdown · LaTeX
      </li>
      <li>
        프레임워크/라이브러리 — React (Next.js) · React Native · Vue.js · Express · Flask ·
        Tensorflow · OpenCV
      </li>
      <li>데이터베이스 — MongoDB (document DB) · MySQL (relational DB)</li>
      <li>운영체제 — Mac · Windows · Ubuntu (Linux) · Raspberry Pi</li>
      <li>
        클라우드 - AWS (EC2, ECR, S3, DynamoDB) · Google Cloud Platform (Maps API, YouTube API)
      </li>
      <li>SDK — PayPal · Philips Hue</li>
      <li>기타 — Git · Docker · Figma · Google Analytics / Tag Manager · Postman · Arduino</li>
      <!-- Fileilla, Notion, MongoDB Compass, Photoshop, Illustrator -->
    </ul>

    <!-- <h2>작업 의뢰 시 준비사항</h2>
    <ol>
        <li>작업 목적 및 기한</li>
        <li>스토리보드 (디자인 시안) - 피그마, 젯플린, 어도비XD 등 다양한 포맷 가능</li>
        <li>기능명세서</li>
        <li>필요 시 안드로이드 / iOS 개발 계정 및 서버 접근 권한 등</li>
        <li>테스트 시나리오 - 개발 후 정상 서비스 확인 용도</li>
    </ol>
    <p>
        위 항목들을 구체적으로 준비하여 제공해주시면 빠르고 정확한 개발이 가능합니다. 아직 아이디어에 머물러있는 프로젝트의 경우 기획 / 디자인 구체화 등을 도와드릴 수 있으나 추가 비용이 발생합니다. 원하시는 경우 내부적으로 기획자·디자이너를 추가 소집할 수 있습니다.
    </p> -->

    <h2>프로젝트 예시</h2>
    <p>아래는 과거 제가 개발한 프로젝트 사례입니다.</p>
    <!-- <p>
        아래는 과거 제가 개발한 프로젝트 및 그에 따른 비용 산정(단위: 만원) 사례입니다. 실제 작업 문의 시에는 상세 화면 / 기능 단위로 더 상세하게 견적을 제시해드립니다.
    </p> -->

    <h3>BuyBus</h3>
    <p>
      외국인을 위한 고속·시외버스 승차권 예매 대행 서비스 (<a
        href="https://buybus.kr"
        target="_blank"
        >링크</a
      >)
    </p>
    <figure class="image">
      <img src="https://images.gunh.ee/freelancing/project_buybus_2.png" style="width: 600px" />
    </figure>
    <!-- <div class="column-list">
        <div class="column column-4">
            <img src="https://images.gunh.ee/freelancing/project_buybus_1.png">
        </div>
        <div class="column column-4">
            <img src="https://images.gunh.ee/freelancing/project_buybus_2.png">
        </div>
        <div class="column column-4">
            <img src="https://images.gunh.ee/freelancing/project_buybus_3.png">
        </div>
        <div class="column column-4">
            <img src="https://images.gunh.ee/freelancing/project_buybus_4.png">
        </div>
    </div> -->
    <ul>
      <li>회원 가입 및 인증 (소셜 로그인)</li>
      <li>실시간 버스 잔여 좌석 조회</li>
      <li>페이팔 등 온라인 결제 시스템 연동</li>
      <li>구글 지도 연동, 승하차 터미널 및 경로 표시</li>
      <li>관리자 도구 (승차권 업로드 및 주문 조회·변경·취소)</li>
    </ul>

    <!-- <Freelancing_Tasks :tasks="taskDefinition_ALLCL" /> -->
    <h3>ALLCL (올클)</h3>
    <p>사용자 참여형 클라이밍 문제 데이터베이스 (모바일 앱)</p>
    <figure style="display: flex" class="image">
      <iframe
        style="border: 1px solid rgba(0, 0, 0, 0.1)"
        width="800"
        height="450"
        src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2F9MtF9AUUERHI4chyHOM6Pj%2FALLCL-mockup%3Ftype%3Ddesign%26node-id%3D0%253A1%26mode%3Ddesign%26t%3D6LwRwox74P46PZF8-1"
        allowfullscreen
      ></iframe>
    </figure>
    <ul>
      <li>회원 가입 및 인증</li>
      <li>사용자 참여형 문제 등록/수정/조회/삭제</li>
      <li>사용자 참여형 등반기록 등록/수정/조회/삭제</li>
      <li>네트워킹 (프로필, 검색, 팔로우)</li>
      <li>문제 난이도 자동 추정 시스템</li>
    </ul>

    <h3>Nupjuk!</h3>
    <p>
      KAIST 학습관리시스템 기능 보강 도구 (크롬 확장 프로그램,
      <a
        href="https://chromewebstore.google.com/detail/jholkhoploepkmjddgnnhlbmokmjcoim"
        target="_blank"
        >링크</a
      >)
    </p>
    <figure class="image">
      <img
        src="https://images.gunh.ee/freelancing/project_nupjuk.png"
        alt=""
        style="width: 600px"
      />
    </figure>
    <ul>
      <li>동영상 강의 탐색 단축키 추가</li>
      <li>다양한 색상 테마 선택 기능 / 다크모드</li>
      <li>기본 아이콘 교체 (벡터화)</li>
      <li>
        기타 간편 기능 (로그인 화면 자동 리다이렉트 / 현재 주차 자동 선택 / 강의 자료 다운로드 버튼
        등)
      </li>
    </ul>

    <!-- <h2>취소 및 환불 규정</h2>
    <h4>가. 기본 환불 규정</h4>
    <ol>
        <li>작업자와 의뢰인의 상호 협의하에 청약 철회 및 환불이 가능합니다.</li>
        <li>
            작업이 완료된 이후 또는 자료, 프로그램 등 서비스가 제공된 이후에는 환불이 불가합니다.<br/>
            (소비자보호법 17조 2항의 5조. 용역 또는 「문화산업진흥 기본법」 제2조 제5호의 디지털콘텐츠의 제공이 개시된 경우에 해당)
        </li>
    </ol>
    <h4>나. 작업자 책임 사유</h4>
    <ol>
        <li>작업자의 귀책사유로 당초 약정했던 서비스를 미이행했거나 보편적인 관점에서 심각하게 잘못 이행한 경우 결제 금액 전체 환불이 가능합니다.</li>
    </ol>
    <h4>다. 의뢰인 책임 사유</h4>
    <ol>
        <li>
            서비스 진행 도중 의뢰인의 귀책사유로 인해 환불을 요청할 경우, 반환 금액을 아래와 같이 계산 후 총 금액의 10%를 공제하여 환불합니다.
            <ul>
                <li>총 작업량의 1/3 경과 전 : 결제한 요금의 2/3</li>
                <li>총 작업량의 1/2 경과 전 : 결제한 요금의 1/2</li>
                <li>총 작업량의 1/2 경과 후 : 반환하지 않음</li>
            </ul>
        </li>
    </ol> -->
  </Page>

  <!-- 

근래 여유가 좀 생겨서 
남는 시간에 프로그래밍 프리랜싱을 뛰어볼까 합니다.
지인들을 통해 서비스 개발 의뢰가 종종 들어오고 
그럴 때마다 상황에 맞추어 답변을 하곤 했는데,
이번 기회에 일반적으로 참고 가능한 업무소개서를 한번 써봤습니다.

창업팀에서 MVP를 만들려고 한다면, 랩에서 연구 관련 프로토타입이
필요하다면, 개인적으로 만들어보고싶은 서비스가 있다면
혹은 그러한 분을 알고 계시다면! 절호의 기회입니다.ㅎㅎ
많은 관심 부탁드립니다!

본 소개서는 프로필 링크나 개인 웹사이트 메인 페이지를 통해 
계속 접근 가능할 예정입니다.

 -->
</template>

<style>
.dark table,
.dark th,
.dark td {
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.dark th {
  color: rgba(255, 255, 255, 0.7);
}
</style>
