<script setup>
import { inject } from "vue"
import Page from "../components/Page.vue"

const { lang } = inject("lang")

const courses = [
  {
    titleKor: "고등 선형안정 및 제어",
    titleEng: "Advanced Linear Stability and Control",
    year: 2024,
    semester: "가을학기",
    department: "항공우주공학과",
    code: "AE.55002",
    type: "선택(석/박사)",
    credits: 3,
    grade: "B+",
    professor: "이창훈",
  },
  {
    titleKor: "항공우주응용 인공지능 기법",
    titleEng: "Artificial Intelligence for Aerospace Applications",
    year: 2024,
    semester: "가을학기",
    department: "항공우주공학과",
    code: "AE.55006",
    type: "선택(석/박사)",
    credits: 3,
    grade: "A+",
    professor: "최한림",
  },
  {
    titleKor: "컨벡스 최적화",
    titleEng: "Convex Optimization",
    year: 2024,
    semester: "가을학기",
    department: "산업및시스템공학과",
    code: "IE.50039",
    type: "선택(석/박사)",
    credits: 3,
    grade: "A0",
    professor: "이다빈",
  },
  {
    titleKor: "세미나 (석사)",
    titleEng: "Seminar (Master)",
    year: 2024,
    semester: "가을학기",
    department: "항공우주공학과",
    code: "AE.93100",
    type: "세미나",
    credits: 1,
    grade: "S",
    professor: "신동혁",
  },
  {
    titleKor: "리더십강좌<2024 가을학기 석사리더십강좌 A반>",
    titleEng: "Special Lecture on Leadership<2024 Fall Semester Master's Leadership Class A>",
    year: 2024,
    semester: "가을학기",
    department: "공통필수",
    code: "CC.50001",
    type: "공통필수",
    credits: 0,
    grade: "S",
    professor: "",
  },
  {
    titleKor: "우주탐사궤도 및 임무해석",
    titleEng: "Space Mission and Orbit Analysis",
    year: 2025,
    semester: "봄학기",
    department: "우주탐사공학학제전공",
    code: "SPE.51000",
    type: "선택(석/박사)",
    credits: 3,
    grade: "",
    professor: "이동헌",
  },
  {
    titleKor: "우주비행체 동역학",
    titleEng: "Spacecraft Attitude Dynamics and Control",
    year: 2025,
    semester: "봄학기",
    department: "항공우주공학과",
    code: "AE.55000",
    type: "선택(석/박사)",
    credits: 3,
    grade: "",
    professor: "윤효상",
  },
  {
    titleKor: "세미나 (석사)",
    titleEng: "Seminar (Master)",
    year: 2025,
    semester: "봄학기",
    department: "항공우주공학과",
    code: "AE.93100",
    type: "선택(석/박사)",
    credits: 1,
    grade: "",
    professor: "신동혁",
  },
  {
    titleKor: "논문연구 (석사)",
    titleEng: "M.S. Thesis",
    year: 2025,
    semester: "봄학기",
    department: "항공우주공학과",
    code: "AE.92100",
    type: "선택(석/박사)",
    credits: 5,
    grade: "",
    professor: "안재명",
  },
  // {
  //   titleKor: "",
  //   titleEng: "",
  //   year: 202,
  //   semester: "학기",
  //   department: "항공우주공학과",
  //   code: "",
  //   type: "선택(석/박사)",
  //   credits: 1,
  //   grade: "",
  //   professor: "",
  // },
]

// const listOfCoursesTitleEnglish =
//   "List of Courses Taken" + (new Date() < new Date("2026-09-01") ? " or Taking" : "")
//
</script>

<template>
  <Page
    :supertitle="lang === 'ko' ? '학력 - 석사' : 'Education - Master of Science'"
    :title="
      lang === 'ko' ? '한국과학기술원 (KAIST)' : 'Korea Advanced Institute of Science & Technology'
    "
    icon="KAIST.png"
  >
    <p v-if="lang === 'ko'">
      <router-link to="/ac/kaist/bs">학력 - 학사</router-link>로부터 이어짐
    </p>
    <p v-else>
      Following <router-link to="/ac/kaist/bs">Education - Bachelor of Science</router-link>
    </p>

    <br />

    <div class="column-list" style="justify-content: flex-start">
      <div class="column" style="width: auto">
        <b>{{ lang === "ko" ? "재학" : "Pursuing" }}</b> (2024.09 - 2026.08 ({{
          lang === "ko" ? "예정" : "projected"
        }}))
      </div>

      <div class="column" style="width: auto">
        <b>{{ lang === "ko" ? "항공우주공학" : "Aerospace Engineering" }}</b>
      </div>
    </div>

    <div v-if="lang === 'ko'">
      <p>
        <a href="http://sai.kaist.ac.kr/" target="_blank">전략적 항공우주문제 연구실</a>
        소속으로, 안재명 교수님의 지도를 받고 있습니다.
      </p>
      <p>
        라그랑주점, 소행성 등을 목표로 하는 심우주 탐사 임무의 궤도를 설계하고 최적화하는 연구를
        주로 합니다.
        <br />
        전산학 복수전공과 개발자로서의 경력을 살려, 특히 컴퓨터를 활용한 혁신적인 궤도 설계 기법을
        정립하는 것에 관심이 많습니다.
      </p>
    </div>
    <div v-else>
      <p>
        As a member of
        <a href="http://sai.kaist.ac.kr/" target="_blank">Strategic Aerospace Initiative</a>, I am
        being advised by professor Jaemyung Ahn.
      </p>
      <p>
        My studies are focused on, but not limited to designing and optimizing spacecraft
        trajectories for deepspace missions targeting Lagrange points, asteroids and such.
        <br />
        I am interested in making a breakthrough in computer-aided trajectory design, following my
        personal background in computer science: a double-major in CS, and careers as a developer.
      </p>
    </div>

    <h2>{{ lang === "ko" ? "출판 논문" : "Publications" }}</h2>
    <h3>{{ lang === "ko" ? "저널" : "Journals" }}</h3>
    <p>{{ lang === "ko" ? "업데이트 예정" : "to be updated" }}</p>
    <h3>{{ lang === "ko" ? "학회" : "Conferences" }}</h3>
    <p>{{ lang === "ko" ? "업데이트 예정" : "to be updated" }}</p>

    <h2>
      {{ lang === "ko" ? "수강 과목 목록" : "Courseworks" }}
      <!-- TODO: 졸업하면 Taking 지우기 -->
    </h2>
    <ul class="toggle">
      <li>
        <details>
          <summary>
            {{ lang === "ko" ? "펼치기/접기" : "expand/collapse" }}
          </summary>
          <br />
          <table>
            <tr v-for="c in courses" :key="c.code">
              <th>
                {{ lang === "ko" ? c.titleKor : c.titleEng }}
                <span class="color-subtitle"
                  >({{ c.code }},
                  {{
                    lang === "ko" ? `${c.year ? c.year + "년 " : ""}${c.semester}`
                    : c.year ?
                      `${{ 봄학기: "Spring", 여름학기: "Summer", 가을학기: "Fall", 겨울학기: "Winter" }[c.semester]} ${c.year}`
                    : "precomplete"
                  }})
                </span>
              </th>
            </tr>
          </table>
        </details>
      </li>
    </ul>
  </Page>
</template>

<style scoped>
th {
  color: inherit;
}
</style>
