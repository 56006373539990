<script setup>
import { onMounted, ref } from "vue"
import axios from "axios"

// import { inject } from 'vue';
import Page from "../components/Page.vue"

document.title = "Burgers"

const brands = ref([])

async function fetchBrands() {
  try {
    brands.value = (await axios.get("/burger-brands.json")).data
    console.log(brands.value)
  } catch (error) {
    console.error(error)
    alert("수제버거 리스트를 불러오는데에 실패했습니다.🥲")
  }
}

onMounted(() => {
  fetchBrands()
})

// const { lang } = inject('lang');
</script>

<template>
  <Page icon="🍔" title="Burgers" koreanOnly>
    <p>
      방문했던 버거집들을 개인적인 선호도 순으로 나열한 목록입니다. 이름은 국내 브랜드는 국문, 외국
      브랜드는 영문 혹은 원어로 표시함을 원칙으로 합니다. 이름을 클릭하면 식당 웹사이트나 인스타그램
      계정 등으로 넘어갈 수 있어요.
    </p>
    <p>
      지속적으로 업데이트됩니다.
      <!-- 목록을 정리하게 된 배경과, 별점을 매기는 기준에 대해서는
      <router-link to="/p/97">이 글</router-link>을 참고해주세요. -->
    </p>
    <!-- <p>클래식, 육즙, raw로 구워서 주거나 굽기를 선택 가능 달면 최악 감튀 순위? 친절함</p> -->

    <h3>기준</h3>
    <p>
      ★★★★★ — 명예의 전당<br />
      ★★★★ — 30분 이상 거리여도 재방문 의사 있음<br />
      ★★★ — 근처에 있다면 재방문 의사 있음<br />
      ★★ — 재방문 의사 없음<br />
      ★ — 되도록이면 피하고 싶음
    </p>
    <p>같은 별점 안에서도 선호도가 높은 순으로 정렬했습니다.</p>

    <template v-for="rating in [5, 4, 3, 2, 1]" :key="rating">
      <h3>{{ "★".repeat(rating) }}</h3>
      <ul v-for="brand in brands" :key="brand.name">
        <li v-if="brand.rating === rating">
          <span class="brand"
            ><a :href="brand.link" target="_blank">{{ brand.name }}</a></span
          >
          <span class="color-subtitle subscript">{{ brand.visits }}</span>
          <!-- <p v-if="brand.notes">{{ brand.notes }}</p> -->
        </li>
      </ul>
      <p v-if="brands.filter((b) => b.rating === rating).length === 0">없음</p>
    </template>

    <h2>Want to Go</h2>
    <ul>
      <li>Gordon Ramsay Burger(센텀시티/롯데월드몰/인천롯데백화점/무역센터/영국/미국/캐나다)</li>
      <li>
        아메리칸 버거위크 참여 브랜드
        <ul>
          <li>GTS버거 (수원/광교/동탄/대전/전주/평택/광명/성수/부천/센텀 등)</li>
          <li>래빗홀버거 (건대)</li>
          <li>멜팅소울 (롯데백화점본점/다산신도시/동탄라크몽/캐리비안베이)</li>
          <li>버거보이 (성수낙낙/신용산)</li>
          <li>버거브라더 (방학)</li>
          <li>브로버거 (방이/광명)</li>
          <li>세븐패티 (가산/낙성대/죽전/영등포/압구정로데오)</li>
          <li>올더웨이 (송파)</li>
          <li>
            텍사스로드하우스
            (현대프리미엄아울렛(스페이스원/송도/김포)/현대백화점(울산/판교/중동)/더현대대구/미국)
          </li>
          <li>폰버거 (광진/미국)</li>
          <li>플렉스버거 (노원/미국)</li>
        </ul>
      </li>
    </ul>
  </Page>
</template>

<style>
.brand {
  font-size: large;
  font-weight: 600;
  margin-right: 0.3rem;
}
</style>
