<script setup>
import { ckpts } from "@/main"
import { usePostsStore } from "../stores/posts"
import { onMounted, ref, inject } from "vue"
import PostListElementInline from "../components/PostListElementInline.vue"

const { lang, toggleLang } = inject("lang")
const { isDark, toggleIsDark } = inject("isDark")

const profileImageUrl = "https://images.gunh.ee/profile-nz.jpg"
const PREVIEW_COUNT = 5

const postsStore = usePostsStore()

const flags = ref(new Set())
const recentPosts = ref(undefined)
const reads = ref(undefined)
const playlists = ref(undefined)

// const hour = new Date().getHours()
// const timeslot =
//   hour < 6 ? "night"
//   : hour < 12 ? "morning"
//   : hour < 18 ? "afternoon"
//   : hour < 21 ? "evening"
//   : "night"

function getPlaylists() {
  playlists.value = postsStore.playlists
    .filter((item) => !isNaN(item.snippet.title.split(" ")[0]))
    .slice(0, PREVIEW_COUNT)
}
function getFlags() {
  // ckpts.sort((a,b) => b.end.localeCompare(a.end));
  flags.value = [
    ...new Set(
      ckpts
        .map((_) => _.country.split(/(\s+)/).filter((_) => _.trim().length > 0))
        .flat()
        .reverse(),
    ),
  ]
}

function getPosts() {
  // const posts = (await scan({ TableName: 'post' })).Items.sort((b,a) => a.created - b.created);

  let recentPosts_ = []
  let reads_ = []

  postsStore.posts.forEach((p) => {
    // p.created = unix2ISO(p.created).slice(0,10);
    // p.modified = unix2ISO(p.modified).slice(0,10);
    if (p.tag && p.tag.includes("책")) {
      reads_.push(p)
    } else {
      recentPosts_.push(p)
    }
  })

  recentPosts_ = recentPosts_.slice(0, PREVIEW_COUNT - 1)

  const recentPostIds = recentPosts_.map((_) => _.id)
  const updates = postsStore.posts
    .filter((p) => !p.tag?.includes("책"))
    .sort((b, a) => a.modified - b.modified)
  let updatedCount = 0
  for (let u of updates) {
    if (updatedCount >= 2) break
    if (new Date().getTime() / 1000 - u.modified > 60 * 60 * 24 * 7) continue
    // if (recentPostIds.includes(u.id)) continue;
    if (recentPostIds.includes(u.id)) {
      recentPosts_.find((_) => _.id === u.id).updated = true
      continue
    }
    u.updated = true
    recentPosts_.push(u)
    updatedCount++
  }

  recentPosts.value = recentPosts_
  reads.value = reads_.slice(0, PREVIEW_COUNT)
}

onMounted(() => {
  document.title = "이건희 Gunhee Yi"
  postsStore.fetchPosts().then(() => {
    getPosts()
  })
  postsStore.fetchPlaylists().then(() => {
    getPlaylists()
  })
  getFlags()
})
</script>

<template>
  <body>
    <article class="page" style="text-align: left">
      <div class="page-body">
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: start;
            gap: 2rem;
          "
        >
          <div style="">
            <h1 style="font-size: 2.5rem; font-weight: 700; margin-bottom: 0.75rem; margin-top: 0">
              {{ { ko: "이건희", en: "Gunhee Yi" }[lang] }}
            </h1>
            <img
              class="mobile-only"
              :src="profileImageUrl"
              alt=""
              srcset=""
              style="width: 200px; height: 200px"
            />
            <p v-if="lang === 'ko'">
              반갑습니다. 이건희입니다.
              <br />
              대학원에서 <router-link to="/ac/KAIST/ms">궤도역학을 연구</router-link>합니다.
              <router-link to="/ac/KAIST/bs">항공우주공학과 전산학을 전공</router-link>했습니다.
              <br /><router-link to="/p/39">김치돼지고기만두</router-link>,
              <router-link to="/brgrs">수제버거</router-link> 그리고
              <router-link to="/p/70">다른 많은 것들</router-link>을 좋아합니다.
            </p>
            <p v-else-if="lang === 'en'">
              <!-- {{
                {
                  morning: `Good morning!`,
                  afternoon: `Good afternoon!`,
                  evening: `Good evening!`,
                  night: `What calls you here at this late night? Hello,`,
                }[timeslot] + ` I'm Aaron.`
              }} -->
              Hey there! I'm Gunhee.
              <br />
              I
              <router-link to="/ac/KAIST/ms">study astrodynamics</router-link> as a graduate
              student, and
              <router-link to="/ac/KAIST">received my bachelor's degree</router-link> in aerospace
              engineering & computer science.<br /><router-link to="/p/39"
                >Kimchi-pork mandus</router-link
              >, <router-link to="/brgrs">homemade burgers</router-link> and
              <router-link to="/p/70">things like those</router-link> are what I love.
            </p>

            <div style="display: flex; gap: 0 1em; flex-wrap: wrap">
              <div>
                <b>Portfolio</b>
                <div class="link link-inline" style="margin-left: 2px">
                  <router-link to="/portfolio">{{ { ko: "보기", en: "view" }[lang] }}</router-link>
                </div>
              </div>
              <div>
                <b>Freelancing</b>
                <div class="link link-inline" style="margin-left: 2px">
                  <router-link to="/freelancing">{{
                    { ko: "보기", en: "view" }[lang]
                  }}</router-link>
                </div>
              </div>
              <div>
                <b>Newsletter</b>
                <div class="link link-inline" style="margin-left: 2px">
                  <router-link to="/newsletter">{{
                    { ko: "구독하기", en: "subscribe" }[lang]
                  }}</router-link>
                </div>
              </div>
            </div>
            <div style="display: flex; gap: 0 1em; flex-wrap: wrap">
              <div>
                <b>Email</b>
                <div class="link link-inline">
                  <a href="mailto:gunny2676@gmail.com" target="_blank"> gunny2676@gmail.com </a>
                </div>
              </div>
              <div>
                <b>GitHub</b>
                <div class="link link-inline">
                  <a href="https://github.com/GunheeYi" target="_blank"> GunheeYi </a>
                </div>
              </div>
              <div>
                <b>LinkedIn</b>
                <div class="link link-inline">
                  <a href="https://www.linkedin.com/in/gunheeyi/" target="_blank"> gunheeyi </a>
                </div>
              </div>
              <div>
                <b>Instagram</b>
                <div class="link link-inline">
                  <a href="https://instagram.com/gunhee_yi" target="_blank"> @gunhee_yi </a>
                </div>
              </div>

              <!-- /&nbsp;&nbsp;&nbsp;
						<div class="link link-inline">
							<a href="#" target="_blank">
								<span class="icon">🇬🇧</span>English version
							</a>
						</div> -->
            </div>

            <div style="display: flex; gap: 0 1em; flex-wrap: wrap">
              <div class="link link-inline">
                📌
                <router-link to="/ckpts" style="word-break: break-all">
                  🇰🇷<span v-for="flag in flags" :key="flag">{{ flag }}</span
                  >🇸🇬
                </router-link>
              </div>
            </div>

            <p>
              <template v-if="lang === 'ko'">
                <a :style="{ cursor: 'pointer' }" @click="toggleLang">Switch to English</a>
                /
                <a :style="{ cursor: 'pointer' }" @click="toggleIsDark">{{
                  { true: "배경 밝게", false: "배경 어둡게" }[isDark]
                }}</a>
              </template>
              <template v-else-if="lang === 'en'">
                <a :style="{ cursor: 'pointer' }" @click="toggleLang">한국어로 변경</a>
                /
                <a :style="{ cursor: 'pointer' }" @click="toggleIsDark">{{
                  { true: "light mode", false: "dark mode" }[isDark]
                }}</a>
              </template>
            </p>
          </div>
          <img
            class="pc-only"
            :src="profileImageUrl"
            alt=""
            srcset=""
            style="width: 300px; height: 300px"
          />
        </div>

        <br />
        <hr />

        <div class="column-list" style="word-break: keep-all">
          <div class="column column-3">
            <h2>{{ { ko: "최근 글", en: "Recent Posts" }[lang] }}</h2>
            <ul>
              <template v-if="recentPosts === undefined">
                <PostListElementInline v-for="i in PREVIEW_COUNT" v-bind:key="i" />
              </template>
              <template v-else>
                <PostListElementInline v-for="p in recentPosts" :key="p.id" :p="p" />
                <li>
                  <figure class="link">
                    <router-link to="/p">
                      {{ { ko: "더보기", en: "more" }[lang] }}
                    </router-link>
                  </figure>
                </li>
              </template>
            </ul>
          </div>
          <div class="column column-3">
            <h2>{{ { ko: "#책", en: "Reads" }[lang] }}</h2>
            <ul>
              <template v-if="reads === undefined">
                <PostListElementInline v-for="i in PREVIEW_COUNT + 1" v-bind:key="i" />
              </template>
              <template v-else>
                <PostListElementInline v-for="p in reads" :key="p.id" :p="p" t="책" />
                <li>
                  <figure class="link">
                    <router-link :to="{ name: `PostList`, query: { t: `책` } }">
                      {{ { ko: "더보기", en: "more" }[lang] }}
                    </router-link>
                  </figure>
                </li>
              </template>
            </ul>
          </div>
          <div class="column column-3">
            <h2>{{ { ko: "재생목록", en: "Playlists" }[lang] }}</h2>
            <ul>
              <template v-if="playlists === undefined">
                <PostListElementInline v-for="i in PREVIEW_COUNT" v-bind:key="i" />
              </template>
              <template v-else>
                <li v-for="pl in playlists" :key="pl.id">
                  <figure class="link">
                    <a :href="`https://www.youtube.com/playlist?list=${pl.id}`" target="_blank">
                      {{ pl.snippet.title }}
                    </a>
                  </figure>
                </li>
                <li>
                  <figure class="link">
                    <a
                      href="https://www.youtube.com/channel/UCYIVUWvarvvnaLTCFbeZU7A/playlists"
                      target="_blank"
                    >
                      {{ { ko: "더보기", en: "more" }[lang] }}
                    </a>
                  </figure>
                </li>
              </template>
            </ul>
          </div>
        </div>

        <br />
        <hr />
        <br />

        <div style="display: flex; gap: 0 1em; flex-wrap: wrap; align-items: center">
          <div style="font-weight: bold">{{ { ko: "기타 링크들", en: "Other Links" }[lang] }}</div>
          <div class="link link-inline">
            <img
              :src="require('../assets/hold.svg')"
              alt=""
              srcset=""
              style="height: 20px; vertical-align: -4px; margin-right: 5px"
            />
            <router-link to="/clmbs">
              <span>Climbs</span>
            </router-link>
          </div>

          <div class="link link-inline">
            <img
              :src="require('../assets/dumbbell.png')"
              alt=""
              srcset=""
              style="height: 20px; vertical-align: -4px; margin-right: 5px"
            />
            <router-link to="/wrkts">
              <span>Workouts</span>
            </router-link>
          </div>

          <div class="link link-inline">
            🍔
            <router-link to="/brgrs">
              <span>Burgers</span>
            </router-link>
          </div>
        </div>

        <br />
        <span style="opacity: 0.5">
          © 2025 {{ { ko: "이건희", en: "Gunhee Yi" }[lang] }}.
          {{ { ko: "최종수정", en: "Last modified" }[lang] }}: 2025-03
        </span>
        <br /><br /><br />
      </div>
    </article>
  </body>
</template>
